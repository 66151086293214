var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-lg","size":"lg","title":"Привязка врачей"},on:{"hide":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"size":"sm","disabled":_vm.selectedDoctors.length <= 0,"variant":"success"},on:{"click":function($event){return _vm.doFormSave()}}},[_vm._v(" Дублировать ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.closeModal}},[_vm._v(" Отменить ")])]},proxy:true}])},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","spinner-variant":"primary","opacity":.8}},[_c('ValidationObserver',{ref:"modalForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Инструмент используется для быстрой привязки врачей в клинику:")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btn-sm btn-danger mb-1 mr-1",on:{"click":function($event){return _vm.clearDoctors()}}},[_vm._v(" Очистить список ")])],1),(!_vm.loading)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"doctors","vid":"doctors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"id":"doctors","multiple":true,"match-keys":['full_name'],"load-options":_vm.loadOptionsNew,"async":true,"default-options":_vm.options,"placeholder":"Выберите докторов..."},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(" "+_vm._s(node.raw.full_name)+" ")])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName},[_vm._v(" "+_vm._s(node.raw.full_name)+" ")])}}],null,true),model:{value:(_vm.selectedDoctors),callback:function ($$v) {_vm.selectedDoctors=$$v},expression:"selectedDoctors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,652155828)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Основные данные"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"clinicData.name",attrs:{"id":"name","autocomplete":"off","placeholder":"Введите название компании"},model:{value:(_vm.clinicData.name),callback:function ($$v) {_vm.$set(_vm.clinicData, "name", $$v)},expression:"clinicData.name"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['clinicData.name'],"suggestions":['«»'],"input-ref-name":"clinicData.name"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ЧПУ URL:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"Введите ЧПУ URL"},model:{value:(_vm.clinicData.slug),callback:function ($$v) {_vm.$set(_vm.clinicData, "slug", $$v)},expression:"clinicData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Город:","label-for":"city_id"}},[_c('ValidationProvider',{attrs:{"name":"city_id","vid":"city_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.citiesList},model:{value:(_vm.clinicData.city_id),callback:function ($$v) {_vm.$set(_vm.clinicData, "city_id", $$v)},expression:"clinicData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Сеть:","label-for":"network_id"}},[_c('ValidationProvider',{attrs:{"name":"network_id","vid":"network_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":[{id: '', name: '------------'} ].concat( _vm.clinicNetworksList),"clearable":false},model:{value:(_vm.clinicData.network_id),callback:function ($$v) {_vm.$set(_vm.clinicData, "network_id", $$v)},expression:"clinicData.network_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Адрес без города:","label-for":"address"}},[_c('ValidationProvider',{attrs:{"name":"address","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"ул. Советская, д. 1"},model:{value:(_vm.clinicData.address),callback:function ($$v) {_vm.$set(_vm.clinicData, "address", $$v)},expression:"clinicData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Телефон:","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите телефон"},model:{value:(_vm.clinicData.phone),callback:function ($$v) {_vm.$set(_vm.clinicData, "phone", $$v)},expression:"clinicData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Широта:","label-for":"latitude"}},[_c('ValidationProvider',{attrs:{"name":"latitude","vid":"latitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"55.4335"},model:{value:(_vm.clinicData.latitude),callback:function ($$v) {_vm.$set(_vm.clinicData, "latitude", $$v)},expression:"clinicData.latitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Долгота:","label-for":"longitude"}},[_c('ValidationProvider',{attrs:{"name":"longitude","vid":"longitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"37.3535"},model:{value:(_vm.clinicData.longitude),callback:function ($$v) {_vm.$set(_vm.clinicData, "longitude", $$v)},expression:"clinicData.longitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Описание как добратся до клиники:","label-for":"transit_help"}},[_c('ValidationProvider',{attrs:{"name":"transit_help","vid":"transit_help"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите Описание как добратся до клиники"},model:{value:(_vm.clinicData.transit_help),callback:function ($$v) {_vm.$set(_vm.clinicData, "transit_help", $$v)},expression:"clinicData.transit_help"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Сайт клиники:","label-for":"web_site_url"}},[_c('ValidationProvider',{attrs:{"name":"web_site_url","vid":"web_site_url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите URL site"},model:{value:(_vm.clinicData.web_site_url),callback:function ($$v) {_vm.$set(_vm.clinicData, "web_site_url", $$v)},expression:"clinicData.web_site_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Партнерский сайт клиники:","label-for":"ad_site_url"}},[_c('ValidationProvider',{attrs:{"name":"ad_site_url","vid":"ad_site_url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите URL site"},model:{value:(_vm.clinicData.ad_site_url),callback:function ($$v) {_vm.$set(_vm.clinicData, "ad_site_url", $$v)},expression:"clinicData.ad_site_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Описание:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{ref:"clinicData.description",attrs:{"id":"description","placeholder":"Введите описание","rows":"3"},model:{value:(_vm.clinicData.description),callback:function ($$v) {_vm.$set(_vm.clinicData, "description", $$v)},expression:"clinicData.description"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['clinicData.description'],"suggestions":['«»'],"input-ref-name":"clinicData.description"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Дополнительное описание(условия в клинике при стационаре) клиники:","label-for":"additional_description"}},[_c('ValidationProvider',{attrs:{"name":"additional_description","vid":"additional_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите дополнительное описание (условия в клинике при стационаре) клиники","rows":"3"},model:{value:(_vm.clinicData.additional_description),callback:function ($$v) {_vm.$set(_vm.clinicData, "additional_description", $$v)},expression:"clinicData.additional_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"График работы:","label-for":"schedule"}},[_c('ValidationProvider',{attrs:{"name":"schedule","vid":"schedule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"clinicData.schedule",attrs:{"id":"name","autocomplete":"off","placeholder":"Введите График работы"},model:{value:(_vm.clinicData.schedule),callback:function ($$v) {_vm.$set(_vm.clinicData, "schedule", $$v)},expression:"clinicData.schedule"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['clinicData.schedule'],"suggestions":['Пн-Пт', 'Пн-Сб', 'Пн-Вс', 'Сб-Вс', 'Круглосуточно', '<sup></sup> - <sup></sup>'],"input-ref-name":"clinicData.schedule"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Официальное название:","label-for":"official_name"}},[_c('ValidationProvider',{attrs:{"name":"official_name","vid":"official_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите Официальное название"},model:{value:(_vm.clinicData.official_name),callback:function ($$v) {_vm.$set(_vm.clinicData, "official_name", $$v)},expression:"clinicData.official_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"ФИО директора:","label-for":"director"}},[_c('ValidationProvider',{attrs:{"name":"director","vid":"director"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите ФИО директора"},model:{value:(_vm.clinicData.director),callback:function ($$v) {_vm.$set(_vm.clinicData, "director", $$v)},expression:"clinicData.director"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Год открытия:","label-for":"opening_year"}},[_c('ValidationProvider',{attrs:{"name":"opening_year","vid":"opening_year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите год открытия"},model:{value:(_vm.clinicData.opening_year),callback:function ($$v) {_vm.$set(_vm.clinicData, "opening_year", $$v)},expression:"clinicData.opening_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Способ оплаты:","label-for":"payment_method"}},[_c('ValidationProvider',{attrs:{"name":"payment_method","vid":"payment_method"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"clinicData.payment_method",attrs:{"id":"name","autocomplete":"off","placeholder":"Введите способ оплаты"},model:{value:(_vm.clinicData.payment_method),callback:function ($$v) {_vm.$set(_vm.clinicData, "payment_method", $$v)},expression:"clinicData.payment_method"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['clinicData.payment_method'],"suggestions":['Наличный', 'Безналичный', 'ДМС', 'ОМС'],"input-ref-name":"clinicData.payment_method"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Типы клиентов:","label-for":"client_types"}},[_c('ValidationProvider',{attrs:{"name":"client_types","vid":"client_types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"clinicData.client_types",attrs:{"id":"name","autocomplete":"off","placeholder":"Введите Типы клиентов"},model:{value:(_vm.clinicData.client_types),callback:function ($$v) {_vm.$set(_vm.clinicData, "client_types", $$v)},expression:"clinicData.client_types"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-baseline"},[_c('fast-insert',{attrs:{"input-ref":_vm.$refs['clinicData.client_types'],"suggestions":['Взрослым', 'Детям'],"input-ref-name":"clinicData.client_types"},on:{"fastInsertSelected":_vm.onSelectedFastInsertSuggestion}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Рейтинг персонала:","label-for":"rating_personal"}},[_c('ValidationProvider',{attrs:{"name":"rating_personal","vid":"rating_personal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите Рейтинг персонала"},model:{value:(_vm.clinicData.rating_personal),callback:function ($$v) {_vm.$set(_vm.clinicData, "rating_personal", $$v)},expression:"clinicData.rating_personal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Рейтинг оснащенности:","label-for":"rating_equipment"}},[_c('ValidationProvider',{attrs:{"name":"rating_equipment","vid":"rating_equipment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите Рейтинг оснащенности"},model:{value:(_vm.clinicData.rating_equipment),callback:function ($$v) {_vm.$set(_vm.clinicData, "rating_equipment", $$v)},expression:"clinicData.rating_equipment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"ID docdoc:","label-for":"id_docdoc"}},[_c('ValidationProvider',{attrs:{"name":"id_docdoc","vid":"id_docdoc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_docdoc","autocomplete":"off","placeholder":"ID в базе Сбер здоровье"},model:{value:(_vm.clinicData.id_docdoc),callback:function ($$v) {_vm.$set(_vm.clinicData, "id_docdoc", $$v)},expression:"clinicData.id_docdoc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Тип клиники:","label-for":"type_id"}},[_c('ValidationProvider',{attrs:{"name":"type_id","vid":"type_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":[{'id': '', 'name': '------------'} ].concat( _vm.clinicTypesList),"clearable":false},model:{value:(_vm.clinicData.type_id),callback:function ($$v) {_vm.$set(_vm.clinicData, "type_id", $$v)},expression:"clinicData.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Врачи"}},[_c('list-doctors',{attrs:{"clinic-city-id":_vm.clinicData.city_id}})],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmitClose}},[_vm._v(" "+_vm._s(_vm.clinicData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),(_vm.clinicData.id)?_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.formSubmitReload}},[_vm._v(" Обновить ")])],1):_vm._e(),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
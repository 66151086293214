<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver
        v-if="!loading"
        ref="form"
      >
        <Form
          :clinic-data.sync="formData"
          :cities-list="citiesList"
          :clinic-networks-list="clinicNetworksList"
          :clinic-types-list="clinicTypes"
          @formSubmitClose="doFormSave"
          @formSubmitReload="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  id: null,
  slug: '',
  name: '',
  city_id: null,
  network_id: null,
  address: '',
  phone: '',
  latitude: '',
  longitude: '',
  transit_help: '',
  web_site_url: '',
  ad_site_url: '',
  description: '',
  schedule: '',
  official_name: '',
  director: '',
  opening_year: '',
  payment_method: '',
  client_types: '',
  rating_personal: '',
  rating_equipment: '',
  id_docdoc: '',
  type_id: null,
})

export default {
  name: 'ClinicsUpdate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo() {
    return {
      title: this.getTitlePage(),
    }
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
      citiesList: [],
      clinicNetworksList: [],
      clinicTypes: [],
    }
  },
  mounted() {
    Promise.all([
      this.doLoadCities(),
      this.doLoadClinicNetworks(),
      this.doLoadClinicTypes(),
    ]).then(() => {
      Promise.all([
        this.doLoadClinic(),
      ]).then(() => {
        this.loading = false
      })
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave(callbackData) {
      this.loading = true

      const formData = new FormDataConverter(this.formData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: `/clinics/${this.$route.params.id}/update`,
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          if (callbackData.reload === false) {
            await this.doLoadClinic()

            this.sendToast({
              title: 'Успех!',
              icon: 'CheckCircleIcon',
              text: 'Клиника успешно обновлена!',
              variant: 'success',
            })
            return
          }
          await this.$router.push({ name: 'clinics' })

          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Клиника успешно обновлена!',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 500) {
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Ошибка на сервере, попробуйте еще раз!',
            variant: 'danger',
          })
        }
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    async doLoadClinic() {
      const response = await axiosIns({
        method: 'GET',
        url: `/clinics/${this.$route.params.id}/show`,
      })

      this.formData = _.merge(this.formData, response.data.data)

      store.commit('app/UPDATE_CURRENT_PAGE_TITLE', this.formData.name)

      return response
    },
    async doLoadCities() {
      const response = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = response.data.data

      return response
    },
    async doLoadClinicNetworks() {
      const res = await axiosIns({
        method: 'GET',
        url: '/clinic_networks/options',
      })

      this.clinicNetworksList = res.data.data

      return res
    },
    async doLoadClinicTypes() {
      const res = await axiosIns({
        method: 'GET',
        url: '/clinic_types/options',
      })

      this.clinicTypes = res.data.data

      return res
    },
    getTitlePage() {
      return `${this.formData?.name ?? ''} - Редактирование клиники`
    },
  },
}
</script>

<template>
  <b-modal
    id="modal-lg"
    size="lg"
    title="Привязка врачей"
    @hide="closeModal"
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      :opacity=".8"
    >
      <ValidationObserver ref="modalForm">
        <b-row>
          <b-col cols="12">
            <h3>Инструмент используется для быстрой привязки врачей в клинику:</h3>
          </b-col>

          <b-col cols="12">
            <b-button
              class="btn-sm btn-danger mb-1 mr-1"
              @click="clearDoctors()"
            >
              Очистить список
            </b-button>
          </b-col>

          <b-col
            v-if="!loading"
            cols="12"
          >
            <validation-provider
              #default="{ errors }"
              name="doctors"
              vid="doctors"
            >
              <!--              {{ options }}-->
              <!--              ====-->
              <!--              {{ selectedDoctors }}-->
              <treeselect
                id="doctors"
                v-model="selectedDoctors"
                :multiple="true"
                :match-keys="['full_name']"
                :load-options="loadOptionsNew"
                :async="true"
                :default-options="options"
                placeholder="Выберите докторов..."
              >
                <div
                  slot="value-label"
                  slot-scope="{ node }"
                >
                  {{ node.raw.full_name }}
                </div>
                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName }"
                  :class="labelClassName"
                >
                  {{ node.raw.full_name }}
                </label>
              </treeselect>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-overlay>

    <template #modal-footer>
      <b-button
        size="sm"
        :disabled="selectedDoctors.length <= 0"
        variant="success"
        @click="doFormSave()"
      >
        Дублировать
      </b-button>
      <b-button
        size="sm"
        variant="danger"
        @click="closeModal"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import _ from 'lodash'
import { Treeselect, ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import axiosIns from '@/libs/axios'

const emptyFormData = {
  id: null,
  clinic_id: null,
}

export default {
  name: 'BindingDoctorsModal',
  components: {
    BModal,
    BOverlay,
    BRow,
    BCol,
    BButton,
    Treeselect,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
      default: null,
    },
    clinicCityId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      options: [],
      selectedDoctors: [],
      shortDoctorsList: [],
      searchOptions: [],
      pending: null,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bvModal.show('modal-lg')

    Promise.all([
      this.formData.clinic_id = this.clinicId,
    ]).then(() => {
      Promise.all([
        this.loadShortListDoctors(),
      ]).then(() => {
        this.loading = false
      })
    })
  },
  methods: {
    // loadOptionsNew: _.debounce(async ({ action, searchQuery, callback }) => {
    //   if (action === ASYNC_SEARCH) {
    //     const res = await axiosIns({
    //       method: 'GET',
    //       url: `/doctors/search?search=${searchQuery}`,
    //     })
    //
    //     Promise.all(
    //       await res.data.data.map(async ({ full_name, id }) => ({ full_name, id })),
    //     ).then(data => {
    //       console.log(data)
    //       callback(null, data)
    //     })
    //   }
    // }, 0),
    // TODO в идеале бы сделать debounce но чет не работает https://github.com/riophae/vue-treeselect/issues/302
    loadOptionsNew({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        axiosIns({
          method: 'GET',
          url: `/doctors/search?search=${searchQuery}&city_id=${this.clinicCityId}&is_show=2&excludeIds=1&clinic_id=${this.clinicId}`,
        }).then(response => {
          callback(null, response.data.data.map(({ full_name, id }) => ({ full_name, id })))
        })
      }
    },
    async loadShortListDoctors() {
      const res = await axiosIns({
        method: 'GET',
        url: `/clinics/${this.clinicId}/shortListDoctorsInClinic`,
      })
      this.shortDoctorsList = res.data.data
      this.options = _.merge(this.options, this.shortDoctorsList.map(({ full_name, id }) => ({ full_name, id })))
    },
    async doFormSave() {
      this.loading = true

      try {
        const res = await axiosIns({
          method: 'POST',
          url: '/clinics/doctorsInsert/',
          data: {
            doctor_ids: this.selectedDoctors,
            clinic_id: this.clinicId,
          },
        })
        this.loading = false

        if (res.data.status === 'success') {
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Все прошло успешно!',
            variant: 'success',
          })

          this.closeModal()
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.modalForm.setErrors(error.response.data.data)
          return false
        }
      }
      return true
    },
    closeModal() {
      this.$destroy()
    },
    clearDoctors() {
      this.selectedDoctors.splice(0, this.selectedDoctors.length)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

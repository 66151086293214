<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      :opacity=".8"
    >
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mr-1"
          @click="openBindingDoctorsModal"
        >
          Привязка врачей
        </b-button>
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form
            class="py-1"
            @submit.prevent
          >
            <b-form-group
              label="По ФИО:"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-name"
                v-model="filters.name"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Статус"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.status"
                size="sm"
                value-field="id"
                text-field="value"
                :options="[{id: null, value: '----------'}, ...DoctorsConstants.STATUS_DOCTORS_LIST]"
              />
            </b-form-group>

            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'doctors-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>
      </div>

      <div
        class="d-flex"
        :class="selectedRows.length > 0 ? 'justify-content-between' : 'justify-content-end'"
      >
        <div
          v-if="selectedRows.length > 0"
          class="d-flex align-items-center"
        >
          <b-dropdown
            id="dropdown-form1"
            ref="dropdown"
            size="sm"
            right
            variant="warning"
            :text="`Действия c (${selectedRows.length})`"
          >
            <b-dropdown-item
              variant="success"
              @click="fastUnpinDoctors(selectedRows, '/clinics/doctorsUnpin', 'отвязать')"
            >
              Отвязать врачей
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <div
            v-if="props.column.label === 'checkbox'"
            class="text-center"
          >
            <b-form-checkbox
              v-model="selectAll"
            />
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ props.column.label }}
          </div>
        </template>

        <template v-slot:table-row="item">
          <div
            v-if="item.column.field === 'checkbox'"
            class="text-center"
          >
            <b-form-checkbox
              v-model="selectedRows"
              :value="item.row.id"
            />
          </div>
          <div
            v-else-if="item.column.field === 'full_name'"
            class="text-center"
          >
            <router-link :to="{name: 'doctors-update', params: {id: item.row.id}}">
              {{ item.row.full_name }}
            </router-link>
          </div>
          <div
            v-else-if="item.column.field === 'is_show'"
            class="text-center"
          >
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE"
              class="text-danger"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE_BY_ALERT"
              class="text-warning"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_VISIBLE"
              class="text-success"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
          </div>
          <div
            v-else-if="item.column.field === 'action'"
            class="text-center"
          >
            <router-link :to="{name: 'doctors-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ item.formattedRow[item.column.field] }}
          </div>
        </template>
      </data-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import _, { parseInt } from 'lodash'
import DataTable from '@/components/DataTable.vue'
import axiosIns from '@/libs/axios'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'
import DoctorsConstants from '@/consts/doctors'

import BindingDoctorsModal from '@/views/clinics/doctors/modal/BindingDoctorsModal.vue'
import Vue from 'vue'

export default {
  name: 'DoctorsList',
  components: {
    BFormCheckbox,
    BDropdownItem,
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BButtonGroup,
    BFormSelect,
    BOverlay,
    BFormInput,
    DataTable,
  },
  mixins: [
    dataTableMixin,
  ],
  props: {
    clinicCityId: {
      type: Number,
      require: true,
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      filterOnURL: false,
      selectedRows: [],
      selectAll: false,
      columns: [
        {
          label: 'checkbox',
          field: 'checkbox',
        },
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Ф.И.О.',
          field: 'full_name',
        },
        {
          label: 'Статус',
          field: 'is_show',
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
      citiesList: [],
      DoctorsConstants,
    }
  },
  watch: {
    selectAll: {
      handler(state) {
        if (state) {
          this.selectedRows = _.map(this.rows, 'id')
        } else {
          this.selectedRows = []
        }
      },
    },
  },
  beforeMount() {
    Promise.all([
      this.doLoadCities(),
    ]).then(() => {
      Promise.all([
        this.doLoadDoctors(),
      ]).then(() => {
        this.loading = false
      })
    })

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadDoctors())
  },
  methods: {
    async doLoadDoctors() {
      const res = await axiosIns({
        method: 'GET',
        url: `/clinics/${this.$route.params.id}/listDoctorsInClinic`,
        params: _.merge(this.filters),
      })

      this.$set(this, 'settings', settingsObjectConverter(res.data.data))
      this.$set(this, 'rows', rowsObjectConverter(res.data.data))

      return res
    },
    async doLoadCities() {
      const res = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = res.data.data

      return res
    },
    getStatusDoctor(id) {
      // eslint-disable-next-line radix
      return _.find(DoctorsConstants.STATUS_DOCTORS_LIST, o => o.id === parseInt(id)).value ?? '—'
    },
    async objectDelete(id) {
      const modalResult = await this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        await axiosIns({
          method: 'DELETE',
          url: `/doctors/${id}/delete`,
        })

        await this.doLoadDoctors()
        this.$swal({
          icon: 'success',
          title: 'Удалено!',
          text: '',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    openBindingDoctorsModal() {
      console.log(this.clinicCityId)
      const componentClass = Vue.extend(BindingDoctorsModal)

      // eslint-disable-next-line new-cap
      const component = new componentClass({
        propsData: { clinicId: parseInt(this.$route.params.id), clinicCityId: this.clinicCityId },
      })
      component.$mount()
    },
    async fastUnpinDoctors(ids, url, message) {
      const modalResult = await this.$swal({
        title: '',
        text: `Вы уверены что хотите ${message}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        const res = await axiosIns({
          method: 'POST',
          url: `${url}`,
          data: {
            ids,
            clinic_id: this.$route.params.id,
          },
        })
        if (res.data.status === 'success') {
          this.loading = true
          await this.doLoadDoctors()
          this.loading = false
          this.selectedRows = []
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Успех!',
            variant: 'success',
          })
        } else {
          this.sendToast({
            title: '',
            icon: 'AlertCircleIcon',
            text: 'Ошибка, попробуй снова!',
            variant: 'danger',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
